<template>
  <div>
    <div class="addBtn" v-if="!showInfo">
      <div class="addbutton" @click="add">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <base-table
      :columns="addTableConfig"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >

      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="gysName" slot-scope="scope">
       <base-select v-if='!showInfo'  :disabled="show(scope.row.useFlag)" :clearable='false'  :options="currentOptions" v-model="scope.row.gysId"  placeholder="请选择" :selectedField="['keyId', 'enterpriseName']" @change="function(data){return changeSupplier(data,scope.$index)}" :filterable='true'></base-select>
        <div v-else>{{scope.row.gysName}}</div>
      </template>
      <template slot="businessNo" slot-scope="scope">
      <base-select v-if='!showInfo' :filterable='true' :disabled="show(scope.row.useFlag)" :max="9999999"  v-model="scope.row.businessNo" :options="scope.row.options" @change="function(data){return changeBusinessNo(data,scope.row.options,scope.$index)}"  :selectedField="['keyId', 'label']" > </base-select>
<div v-else>{{scope.row.businessNo}}</div>
      </template>
      <template slot="meetingBatchNo" slot-scope='scope'>
        {{getMeetingBatchNo(scope.row.meetingBatchNo,scope.$index)}}
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
          v-if="!scope.row.useFlag"
          content="删除"
          icon="iconfont iconshanchu1"
          @click="dellInfo(scope.$index)"
        />
      </template>

    </base-table>
  </div>
</template>

<script>
import { supplierApi } from '@/api/companyApi'
import { businessManageApi } from '@/api/businessApi'
import BaseSelect from '@/components/common/base-select/new-base-select.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'

import { addTableConfig } from '../utils/config.js'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
export default {
  components: { BaseTable, IconButton, BaseSelect },
  props: {
    meetingBatches: Array,
    showInfo: Boolean,
    gysData: Array
  },
  data () {
    return {
      currentOptions: []
    }
  },
  // 计算属性 类似于data概念
  computed: {
    supplierApi () {
      return supplierApi
    },
    tableData: {
      get () {
        return this.meetingBatches
      },
      set (val) {
        this.$emit('changeMeetingBatches', val)
      }
    },
    addTableConfig () {
      return addTableConfig(this)
    },
    api () {
      return businessManageApi
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    show (useFlag) {
      if (this.showInfo) {
        return true
      }
      if (useFlag) {
        return true
      } else {
        return false
      }
    },
    // 选择业务编号
    changeBusinessNo (data, options, index) {
      options.forEach((item) => {
        if (data === item.keyId) {
          this.$set(this.tableData[index], 'businessId', item.businessNo)
        }
      })
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then(res => {
        if (res.success) {
          console.log(res.data, 2)
          this.currentOptions = res.data
        }
      })
    },
    // 修改供应商
    changeSupplier (data, index) {
      this.api.getGys({ gysId: data }).then(res => {
        console.log(res.data)
        const option = []
        res.data.forEach((item) => {
          option.push({ keyId: item.businessNo, label: item.businessNo, businessNo: item.keyId })
        })
        this.currentOptions.forEach((item) => {
          if (item.keyId === data) {
            this.tableData[index].gysName = item.enterpriseName
          }
        })
        this.$set(this.tableData[index], 'businessNo', '')
        this.$set(this.tableData[index], 'options', option)
        console.log(this.tableData)
      })
    },
    // 获取会议批次号
    getMeetingBatchNo (data, index) {
      let newIndex = index + 1
      newIndex = index + 1
      if (newIndex < 10) {
        newIndex = 0 + '' + newIndex
      }

      const date = new Date(this.$attrs.startMeetingDate)
      const YYYY = (date.getFullYear()) + ''
      let MM = (date.getMonth() + 1) + ''
      if (MM * 1 - 10 < 0) {
        MM = 0 + MM
      }
      let DD = (date.getDate()) + ''
      if (DD * 1 - 10 < 0) {
        DD = 0 + DD
      }
      const meetingBatchNo = YYYY + MM + DD + newIndex
      this.tableData[index].meetingBatchNo = meetingBatchNo
      return meetingBatchNo
    },
    add () {
      // 先选时间才能新增
      console.log(this.$attrs.endMeetingDate, this.$attrs.startMeetingDate)
      if (!this.$attrs.endMeetingDate || !this.$attrs.startMeetingDate) {
        this.warning('请先选择会议时间')
        return
      }
      if (this.tableData.length < 10) {
        this.tableData.push({})
      } else {
        this.warning('会议批次最大为10')
      }
    },
    dellInfo (index) {
      this.tableData.splice(index, 1)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.gysList()
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.addBtn {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  .addButton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
