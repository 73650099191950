// import BaseSelect from '@/components/common/base-select/base-select'
// import { getDictLists } from '@/filters/fromDict'
import { formatDate } from '@/utils/auth/common'
import MeetingBatch from '../components/meeting-batch.vue'
// 查询
export const financingConfig = (content) => {
  return [
    {
      label: '会议类型',
      prop: 'meetingType',
      rules: [{ required: false, message: '请输入会议类型', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 200
      }

    },
    {
      label: '会议开始时间',
      prop: 'startMeetingDate',
      rules: [{ required: false, message: '请输入会议主题', trigger: 'blur' }],
      attrs: {
        type: 'date',
        placeholder: '请选择',
        editable: false,
        'value-format': 'timestamp'
      },
      tag: 'el-date-picker'
    },
    {
      label: '会议结束时间',
      prop: 'endMeetingDate',
      tag: 'el-date-picker',
      rules: [{ required: false, message: '请选择会议时间', trigger: 'blur' }],
      attrs: {
        type: 'date',
        placeholder: '请选择',
        'value-format': 'timestamp',
        editable: false
      }
    }

  ]
}
// 表格
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code'

    },

    {
      label: '会议类型',
      prop: 'meetingType'

    },

    {
      label: '会议开始时间',
      prop: 'startMeetingDate',
      formatter: row => {
        return formatDate(row.startMeetingDate, 'YY/MM/DD hh:mm:ss')
      }

    },
    {
      label: '会议结束时间',
      prop: 'endMeetingDate',
      formatter: row => {
        return formatDate(row.endMeetingDate, 'YY/MM/DD hh:mm:ss')
      }

    },
    {
      label: '备注',
      prop: 'remark',
      formatter: row => {
        return row.remark ? row.remark : '--'
      },
      align: 'left'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 表格
export const addTableConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code'

    },

    {
      label: '供应商名称',
      prop: 'gysName',
      HiddenOverflow: true,
      align: 'left'
    },

    {
      label: '业务编号',
      prop: 'businessNo',
      HiddenOverflow: true

    },
    {
      label: '会议批次号',
      prop: 'meetingBatchNo'

    },

    {
      label: '操作',
      prop: 'action',
      isHidden: content.showInfo
    }
  ]
}

// 新增修改表单
export const addForm = (content) => {
  // const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  // 到期日
  const endOptions = {

    disabledDate (time) {
      // console.log(content.addQueryParas.startMeetingDate === true, (new Date((content.addQueryParas.startMeetingDate))).toString().split(' ')[4])
      if (content.addQueryParas.startMeetingDate) {
        // console.log(Date((content.addQueryParas.startMeetingDate)).split(' ')[4], content.addQueryParas.startMeetingDate, new Date(content.addQueryParas.startMeetingDate))
        const one = 24 * 3600 * 1000

        const minTime = new Date(new Date(content.addQueryParas.startMeetingDate).toLocaleDateString()).getTime()
        const maxTime = new Date(new Date(content.addQueryParas.startMeetingDate).toLocaleDateString()).getTime() + one

        return time.getTime() < minTime || time.getTime() > maxTime
      } else {
        return time.getTime() <= new Date('1990-01-01')
      }
    },

    selectableRange: `${content.addQueryParas.startMeetingDate ? ((new Date((content.addQueryParas.startMeetingDate + 1))).toString().split(' ')[4]) : '00:00:00'} - 23:59:59`

  }
  const startOptions = {
    disabledDate (time) {
      return time.getTime() < new Date('2000-01-01')
    }
  }
  return [
    {
      label: '会议类型',
      prop: 'meetingType',
      rules: [{ required: true, message: '请输入会议类型', trigger: 'change' }],
      attrs: {
        placeholder: '请输入',
        disabled: content.pass,
        maxlength: 200
      },
      span: 8
    },
    {
      label: '会议开始时间',
      prop: 'startMeetingDate',
      rules: [{ required: true, message: '请选择会议开始时间', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        type: 'datetime',
        'value-format': 'timestamp',
        pickerOptions: startOptions,
        disabled: content.pass,
        editable: false
      },
      on: {
        change () {
          content.changeStartMeetingDate()
        }
      },
      tag: 'el-date-picker',
      span: 8

    },
    {
      label: '会议结束时间',
      prop: 'endMeetingDate',
      rules: [{ required: true, message: '请选择会议结束时间', trigger: 'change' }],
      attrs: {
        placeholder: '请选择会议结束时间',
        type: 'datetime',
        'value-format': 'timestamp',
        pickerOptions: endOptions,
        disabled: content.pass,
        editable: false
      },
      on: {
        change (data) {
          content.changeDate(data)
        }
      },
      tag: 'el-date-picker',
      span: 8
    },
    {
      label: '会议批次',
      prop: 'reviewStatus',
      rules: [{ required: true, message: '请完善会议批次', trigger: 'change' }],
      attrs: {
        gysData: content.gysData,
        showInfo: content.showInfo,
        meetingBatches: content.addQueryParas.meetingBatches,
        startMeetingDate: content.addQueryParas.startMeetingDate,
        endMeetingDate: content.addQueryParas.endMeetingDate
      },
      on: {
        changeMeetingBatches (data) {
          console.log(data)
        }
      },
      tag: MeetingBatch,
      span: 24
    },
    {
      label: '备注',
      prop: 'remark',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        rows: 4,
        maxlength: 500,
        readonly: content.showInfo
      },
      span: 24
    }
  ]
}
